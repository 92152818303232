<template>
  <div>
    <v-row class="mt-4 mb-2">
      <v-col align="center">
        <p class="text-h3 my-16 primary--text font-weight-bold">
          Products
        </p>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col md="6" v-for="product in products" :key="product.name" align="center" class="mb-16">
          <p class="text-h6 accent--text font-weight-bold">
            {{ product.name }}
          </p>
          <a :href="product.url" target="_blank">
            <v-img :src="getAssetImg(product.img)" height="300" contain></v-img>
            <v-btn color="primary">Shop Now</v-btn>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ProductListView",
  components: {},
  data() {
    return {
      products: [
        {
          name: 'Berries & Citrus',
          img: 'photos/STUDIO/BOX/BoxBerries.png',
          url: 'https://www.hitimewine.net/good-bev-co.-berries-citrus-12pk-200ml-122269'
        },
        {
          name: 'Margarita',
          img: 'photos/STUDIO/BOX/BoxMarg.png',
          url: 'https://www.hitimewine.net/good-bev-co.-margarita-12pk-200ml-122268'
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>